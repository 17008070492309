import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { convertToHoursAndMinutes } from '../utils/timeUtils';


function TimesheetView({isScreenSmall}) {
  const [timesheets, setTimesheets] = useState([]);
  const [filteredTimesheets, setFilteredTimesheets] = useState([]);
  const { user, isLoading } = useAuth0();
  const [selectedDate, setSelectedDate] = useState('');

  const convertDate = (dateToConvert) => {
    const date = new Date(dateToConvert);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    return `${day}/${month}/${year}`;
  };


  useEffect(() => {
    const fetchTimesheets = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/timesheets/${user.sub}`);
        const timesheetsData = response.data;
        setTimesheets(timesheetsData);
      } catch (error) {
        throw error;
      }
    };

    if (user) {
      fetchTimesheets();
    }
  }, [user?.sub]);


  useEffect(() => {
    if (selectedDate) {
      const filtered = timesheets.filter((timesheet) => {
        const fromDate = new Date(timesheet.fromDate);
        const selected = new Date(selectedDate);
        return fromDate.toDateString() === selected.toDateString();
      });
      setFilteredTimesheets(filtered);
    } else {
      setFilteredTimesheets(timesheets);
    }
  }, [selectedDate, timesheets]);


  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <div className='timesheetViewWrapper'>
      <div className='featureTitle'>
        <h6>Mes feuilles d'heure</h6>
      </div>

      <div className='labelInput timeSheetHeader'>
        <label htmlFor="dateFilter">Sélectionner une Dâte :</label>
        <input type="date" id="dateFilter" value={selectedDate} onChange={handleDateChange} />
      </div>
      <table className='timesheetViewTable'>
        <thead>
          <tr>
            <th><h6>Date</h6></th>
            <th><h6>Total hebdommadaire</h6></th>
            <th><h6>Total Hrs Supp</h6></th>
            <th><h6>Total nuitées</h6></th>
            <th><h6>Astreinte</h6></th>
          </tr>
        </thead>
        <tbody>

          {filteredTimesheets.map((timesheet) => (
            <tr className='tableData' key={timesheet._id}>
              <td><p>{convertDate(timesheet.fromDate)}</p></td>
              <td><p>{convertToHoursAndMinutes(timesheet.weeklyTotalHours)}</p></td>
              <td><p>{convertToHoursAndMinutes(timesheet.overtimeHours)}</p></td>
              <td><p>{timesheet.overnightStays}</p></td>
              <td><p>{timesheet.onCallDuty ? 'Oui' : 'Non'}</p></td>
              <td>
                <button className='btn Link'><a href={`/feuille-heure/${timesheet._id}`}>Voir</a></button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TimesheetView;
