import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';


const Dropdown = ({ options, setFunction, state, label}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectItem = (option) => {
    setFunction(option);
    setTimeout(() => {
      setIsOpen(false);
    }, 0);
  }

  return (
    <div ref={dropdownRef} className='dd'>
      <button
        type="button"
        className={isOpen ? "dd-header-open" : "dd-header"}
        onClick={toggleDropdown}
      >
        <div className='dd-header-wrapper'>
          <div className={`dd-header-title${state ? " selected" : ""}`}><p>{state ? state : label}</p></div>
          <div className="icon">
            {isOpen ? (
              <FontAwesomeIcon icon={faAngleUp} size="2x" />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} size="2x" />
            )}
          </div>
        </div>
      </button>
      {isOpen && (
        <div role="list" className="dd-list">
          {options.map((option) => (
            <button
              type="button"
              className='dd-list-item'
              key={option}
              onClick={(e) => {
                selectItem(option)
              }}
            >
              <p>{option}</p>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
