import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DashboardFilters from './DashboardCeoFilters';


const DashboardCEO = ({isScreenSmall, admin}) => {
    // Render your data
    return (
      <div>
        {isScreenSmall ? (
          <p>Cette page n'est disponible que version ordinateur</p>
        ) : (
          <div className='dashboardWrapper'>
            <DashboardFilters admin={admin}/>
          </div>
        )}
      </div>
    );
};

export default DashboardCEO;
