import React, { useEffect, useState } from 'react';
import axios from 'axios'
import DashboardCeoChart from './DashboardCeoChart';
import DashboardCeoTable from './DashboardCeoTable';
import { useAuth0 } from '@auth0/auth0-react';


const DashboardFilters = ({admin}) => {
    const [filters, setFilters] = useState({
        fromDate: '',
        toDate: '',
        employeeName: '',
        customerId: ''
    });
    const [users, setUsers] = useState([]);
    const [dataChart, setDataChart] = useState();
    const [dataTable, setDataTable] = useState();
    const { user, isLoading } = useAuth0();

    const handleChange = (event) => {
      if (event.target.name === 'absence' && filters.service) {
        setFilters(filters.service = '');
      } else if (event.target.name === 'service' && filters.absence) {
        setFilters(filters.absence = '');
      }
      setFilters({
        ...filters,
        [event.target.name]: event.target.value
    });
    };

    const handleSubmit = async (event) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/dashboard/getDashboardData`, { params: {filters: filters, user: user} });
        setDataChart(response.data.processedDataChart)
        setDataTable(response.data.processDataTable)
      } catch (error) {
        throw error;
      }
    };

    // Fetch users
    useEffect(() => {
      try {

        if (!isLoading) {
          async function fetchUsers() {
            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/users`, {params: {user: user}});
            setUsers(res.data);
          }
          fetchUsers();
        }
      } catch (error) {
        throw error
      }

    }, [isLoading, admin]);

    if (!admin) {
      return <h3>Chargement ...</h3>
    }

    return (
      <div className='dashboardCeoWrapper'>
        <div className='dashboardCeoFilters'>
          <div className='labelInput'>
            <label>Salarié</label>
            <select name="employeeName" value={filters.employeeName} onChange={handleChange}>
                <option value="">Sélectionner un salarié</option>
                {users.map((user) => (
                  <option key={user.firstName + " " + user.lastName} value={user.firstName + " " + user.lastName}>{user.firstName + " " + user.lastName}</option>
              ))}
            </select>
          </div>
            <div className='labelInput'>
              <label>Du</label>
              <input
                  type="date"
                  name="fromDate"
                  value={filters.fromDate}
                  onChange={handleChange}
              />
            </div>

            <div className='labelInput'>
              <label>Au</label>
              <input
                type="date"
                name="toDate"
                value={filters.toDate}
                onChange={handleChange}
              />
            </div>
            <div className='labelInput'>
              <label>N° BC/BL:</label>
              <input
                  type="text"
                  name="customerId"
                  value={filters.customerId}
                  onChange={handleChange}
              />
            </div>
            <div>
              <button className='btn' type="submit" onClick={handleSubmit}>Rechercher</button>
            </div>
          </div>
          <div className='dashboardCeoChart'>
          {dataChart && <DashboardCeoChart dataChart={dataChart}/>}
          </div>
          <div>
            {dataTable && <DashboardCeoTable  dataTable={dataTable}/>}
          </div>
      </div>
    );
};

export default DashboardFilters;
