import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { addDays, subDays, formatISO, startOfWeek, subWeeks, isValid } from 'date-fns'; // Import necessary functions from date-fns
import 'react-date-range/dist/styles.css'; // Import the styles
import 'react-date-range/dist/theme/default.css'; // Import the theme
import Dropdown from './Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { convertToHoursAndMinutes, formatDate, getDaysBetween } from '../utils/timeUtils';
import { capitalizeFirstAndThirdWord } from '../utils/charactersConverter';
import {useNavigate} from 'react-router-dom'


function TimesheetForm({username}) {
  const { user, isLoading } = useAuth0();
  const [selectedWeek, setSelectedWeek] = useState('');
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState(null);
  const [showCheckmark, setShowCheckmark] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]); // New state for selected days
  const navigate = useNavigate();


  const calculateTotalHours = (dayIndex) => {
    let totalHours = 0;
    const dailyDetails = formState.dailyDetails[dayIndex];
    for (let i = 0; i < dailyDetails.length; i++) {
      const line = dailyDetails[i];
      const serviceType = line.serviceType;
      if (serviceType !== "ABS" && serviceType !== "AT" && serviceType !== "M") {
        const hoursWorked = Number(line.hoursWorked) || 0;
        totalHours += hoursWorked;
      }
    }

    return totalHours;
  };

  const [formState, setFormState] = useState({
    employeeId: '',
    employeeName: username,
    fromDate: '',
    toDate: '',
    onCallDuty: false,
    dailyDetails: Array.from({ length: 7 }, () => [
      {
        _id: '',
        date: '',
        customerId: '',
        serviceType: '',
        startWorkHours: '',
        endWorkHours: '',
        hoursWorked: '',
        overnightStay: false,
        comment: '',
      },
    ]),
    rest: false,
    weeklyTotalHours: 0,
    overtimeHours: 0,
    overnightStays: 0
  });

  const previousFromDateRef = useRef(null);

  useEffect(() => {
    if (!isLoading && user) {
      setFormState((prevState) => ({ ...prevState, employeeId: user.sub, employeeName: username }));

      if (formState.fromDate) {
        const currentFromDate = formState.fromDate.toISOString();
        if (currentFromDate !== previousFromDateRef.current) {
          fetchTimesheetData();
          previousFromDateRef.current = currentFromDate;
        }
      }
    }
  }, [isLoading, user, getAccessTokenSilently, formState.fromDate]);



  const deleteDailyDetail = async (id, dayIndex, lineIndex) => {

    const serviceType = formState.dailyDetails[dayIndex][lineIndex].serviceType
    let updatedState;

    if (id !== undefined) {
      try {
        const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/daily-detail/delete-detail/${id}`);
        const removedDetail = response.data;

        if (removedDetail) {
          // Deletion successful, update the form state and perform necessary operations
          const newDailyDetails = formState.dailyDetails.map((day, dIndex) => {
            if (dIndex === dayIndex) {
              return day.filter((line, lIndex) => lIndex !== lineIndex);
            }
            return day;
          });

          if (serviceType !== "ABS" && serviceType !== "AT" && serviceType !== "M") {
            const lineData = formState.dailyDetails[dayIndex][lineIndex];
            const newWeeklyTotalHours = formState.weeklyTotalHours - (Number(lineData.hoursWorked) || 0);
            const newOvernightStays = lineData.overnightStay ? formState.overnightStays - 1 : formState.overnightStays;
            const newOvertimeHours = newWeeklyTotalHours > 39 ? newWeeklyTotalHours - 39 : 0;

            updatedState = {
              ...formState,
              dailyDetails: newDailyDetails,
              weeklyTotalHours: newWeeklyTotalHours,
              overnightStays: newOvernightStays,
              overtimeHours: parseFloat(newOvertimeHours.toFixed(2))
            };


          } else {
            updatedState = {
              ...formState,
              dailyDetails: newDailyDetails
            };

          }
          // Update the timesheet in the backend
          await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/timesheet/createOrUpdateTimesheet`, {formState: formState, user: user}, {
            headers: {
              Authorization: `Bearer ${token}` // Replace 'token' with your actual token
            }
          });

          setFormState(updatedState);
        } else {
          return;
        }
      } catch (err) {
        return;
      }
    } else {
      // Handle the case when id is undefined (newly added detail)
      if (serviceType !== "ABS" && serviceType !== "AT" && serviceType !== "M") {
        const lineData = formState.dailyDetails[dayIndex][lineIndex];
        const newWeeklyTotalHours = formState.weeklyTotalHours - (Number(lineData.hoursWorked) || 0);
        const newOvernightStays = lineData.overnightStay ? formState.overnightStays - 1 : formState.overnightStays;
        const newOvertimeHours = newWeeklyTotalHours > 39 ? newWeeklyTotalHours - 39 : 0;

        setFormState(prevState => {
          const newState = { ...prevState };
          newState.dailyDetails[dayIndex].splice(lineIndex, 1);
          newState.weeklyTotalHours = newWeeklyTotalHours;
          newState.overnightStays = newOvernightStays;
          newState.overtimeHours = parseFloat(newOvertimeHours.toFixed(2))

          return newState;
        });


      } else {
        setFormState(prevState => {
          const newState = { ...prevState };
          newState.dailyDetails[dayIndex].splice(lineIndex, 1);

          return newState;
        });

      }
    }
  };

  const deleteFirstDaylydetail = (id, dayIndex, lineIndex) => {

    const newDailyDetail = {comment: '', customerId: '', date: formState.dailyDetails[dayIndex][lineIndex].date,
    endWorkHours: '', hoursWorked: '', overnightStay: false,
    serviceType: '', startWorkHours: '' }
    const newWeeklyTotalHours = formState.weeklyTotalHours - (formState.dailyDetails[dayIndex][lineIndex].hoursWorked || 0);
    const newOvernightStays = formState.dailyDetails[dayIndex][lineIndex].overnightStay ? formState.overnightStays - 1 : formState.overnightStays;
    const newOvertimeHours = newWeeklyTotalHours > 39 ? newWeeklyTotalHours - 39 : 0;

    setFormState(prevState => {
      const newState = { ...prevState };
      newState.dailyDetails[dayIndex][lineIndex] = newDailyDetail;
      newState.weeklyTotalHours = newWeeklyTotalHours;
      newState.overnightStays = newOvernightStays;
      newState.overtimeHours = newOvertimeHours;

      return newState;
    });

  }


  const fetchTimesheetData = async () => {
    try {
      const employeeId = user.sub;
      const fromDate = formState.fromDate;
      const url = `${process.env.REACT_APP_API_BASE_URL}/api/timesheet/getTimesheet?fromDate=${encodeURIComponent(fromDate)}&employeeId=${employeeId}`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const data = response.data[0];
      if (data && data.employeeId) {


        setFormState({
          employeeId: data.employeeId,
          employeeName: data.employeeName,
          fromDate: new Date(data.fromDate),
          toDate: new Date(data.toDate),
          onCallDuty: data.onCallDuty,
          /**
           * Transform and map dailyDetails for a week starting from Saturday to Friday.
           *
           * 1. `new Date(detail.date).getDay()`: Extracts the day of the week from the provided date.
           *    - Returns a number from 0 (Sunday) to 6 (Saturday).
           *
           * 2. The loop iterates 7 times, representing each day of the week.
           *    - The variable `i` ranges from 0 (Saturday) to 6 (Friday).
           *
           * 3. `(i + 6) % 7`: Remaps the index `i` to match the corresponding `getDay()` value.
           *    - When `i` is 0 (Saturday), it maps to 6 (Saturday in getDay()).
           *    - When `i` is 6 (Friday), it maps to 5 (Friday in getDay()).
           *    - This ensures that the extracted day from the date aligns with the week starting from Saturday.
           *
           * The resulting structure organizes dailyDetails based on the desired week structure.
           */
          dailyDetails: Array(7).fill().map((_, i) =>
          data.dailyDetails.filter(detail =>
            new Date(detail.date).getDay() === (i + 6) % 7
          ).map((detail) => ({
            _id: detail._id,
            date: new Date(detail.date),
            customerId: detail.customerId,
            serviceType: detail.serviceType,
            endWorkHours: detail.endWorkHours,
            hoursWorked: detail.hoursWorked ? detail.hoursWorked.toString() : "", // convert number to string or default to empty string
            overnightStay: detail.overnightStay,
            comment: detail.comment,
            startWorkHours: detail.startWorkHours
          }))
        ),
          rest: data.rest,
          weeklyTotalHours: data.weeklyTotalHours,
          overnightStays: data.overnightStays,
          overtimeHours: data.overtimeHours
        });
      }
    } catch (error) {
      throw error;
    }
  }


  const updateDailyDetailsFieldValue = (event, dayIndex, lineIndex, fieldName) => {

    const { value, type, checked } = event.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    const newDailyDetails = [...formState.dailyDetails];

    newDailyDetails[dayIndex][lineIndex][fieldName] = fieldValue;
    return newDailyDetails;
  }

  const calculateTotals = (dailyDetails) => {

    let totalHours = 0;
    let totalOvernightStays = 0;

    dailyDetails.forEach((dayDetails) => {
      dayDetails.forEach((line) => {
        if (line.serviceType !== "ABS" && line.serviceType !== "AT" && line.serviceType !== "M") {
          totalHours += parseFloat((Number(line.hoursWorked) || 0).toFixed(2));
        }
        totalOvernightStays += line.overnightStay ? 1 : 0;
      });
    });

    return { totalHours, totalOvernightStays };
  }

  const handleDailyDetailsChange = (dayIndex, lineIndex, fieldName) => (event) => {
    let newDailyDetails = updateDailyDetailsFieldValue(event, dayIndex, lineIndex, fieldName);
    let diff;

    if (['startWorkHours', 'endWorkHours'].includes(fieldName)) {
      let startHourParts = newDailyDetails[dayIndex][lineIndex].startWorkHours
      let endHourParts = newDailyDetails[dayIndex][lineIndex].endWorkHours

      if (startHourParts && endHourParts) {
        startHourParts = newDailyDetails[dayIndex][lineIndex].startWorkHours.split(':');
        endHourParts = newDailyDetails[dayIndex][lineIndex].endWorkHours.split(':');
      }

      if (startHourParts.length === 2 && endHourParts.length === 2) {
        const startHour = parseInt(startHourParts[0], 10) + parseInt(startHourParts[1], 10) / 60;
        const endHour = parseInt(endHourParts[0], 10) + parseInt(endHourParts[1], 10) / 60;
        diff = endHour - startHour;

        newDailyDetails[dayIndex][lineIndex].hoursWorked = diff >= 0 ? parseFloat(diff.toFixed(2)) : 0;
      }
    }


    let totalHours;
    let totalOvernightStays;
    let totalOvertimeHours;

    const serviceType = newDailyDetails[dayIndex][lineIndex].serviceType;
    const totals = calculateTotals(newDailyDetails);



    if (serviceType !== "ABS" && serviceType !== "AT" && serviceType !== "M") {
      totalHours = totals.totalHours;
      totalOvernightStays = totals.totalOvernightStays;
      totalOvertimeHours = totalHours > 39 ? totalHours - 39 : 0;
    } else {
      totalHours = totals.totalHours
      totalOvernightStays = totals.totalOvernightStays;
      totalOvertimeHours = totalHours > 39 ? totalHours - 39 : 0;
    }

    const updatedFormState = {
      ...formState,
      dailyDetails: newDailyDetails,
      weeklyTotalHours: totalHours,
      overnightStays: totalOvernightStays,
      overtimeHours: parseFloat(totalOvertimeHours.toFixed(2))
    };

    setFormState(updatedFormState);
  };

  const handleSubmit = async (event) => {

    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/timesheet/createOrUpdateTimesheet`, {formState: formState, user: user}, {
        headers: {
          Authorization: `Bearer ${token}` // Replace 'token' with your actual token
        }
      });

      setShowCheckmark(true);

      setTimeout(() => {
        setShowCheckmark(false);
        navigate('/');
      }, 2000);

    } catch (error) {
      throw error
    }
  };

  const handleAddLine = (dayIndex) => {

    const newDailyDetails = [...formState.dailyDetails];
    const day = new Date(formState.fromDate);
    day.setDate(day.getDate() + dayIndex);
    const newLine = {
      date: formatISO(day, { representation: 'date' }),
      customerId: '',
      serviceType: '',
      endWorkHours: '',
      startWorkHours: '',
      hoursWorked: 0,
      overnightStay: false,
      comment: '',
    };
    newDailyDetails[dayIndex].push(newLine);

    setFormState((prevState) => ({
      ...prevState,
      dailyDetails: newDailyDetails,
    }));
  };


  const handleOnCallDutyChange = (event) => {

    const { checked } = event.target;

    setFormState((prevState) => ({
      ...prevState,
      onCallDuty: checked,
    }));
  };

  const handleRest = (event) => {

    const { checked } = event.target;

    setFormState((prevState) => ({
      ...prevState,
      rest: checked,
    }));
  };


  const handleWeekSelectChange = (option) => {
    const selectedWeek = option;
    let startDate, endDate;


    if (option === 'Cette semaine') {
      startDate = subDays(startOfWeek(new Date(), { weekStartsOn: 0 }), 1); // Saturday of the last week
      endDate = addDays(startOfWeek(new Date(), { weekStartsOn: 0 }), 5);   // Friday of the current week
    } else if (option === 'La semaine dernière') {
      startDate = subDays(startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 0 }), 1); // Saturday of the week before the previous week
      endDate = addDays(startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 0 }), 5);   // Friday of the previous week
    } else if (option === 'La semaine précédente') {
      startDate = subDays(startOfWeek(subWeeks(new Date(), 2), { weekStartsOn: 0 }), 1); // Saturday of the week two weeks ago
      endDate = addDays(startOfWeek(subWeeks(new Date(), 2), { weekStartsOn: 0 }), 5);   // Friday of the week before the previous week
    }


    // Convert both to Date objects if they are not already
    const startDateObj = new Date(startDate);
    const formDateObj = new Date(formState.fromDate);


    // Compare date values
    if (startDateObj.getTime() === formDateObj.getTime()) {
        fetchTimesheetData();
    }

    setSelectedWeek(selectedWeek);

    const newDailyDetails = Array.from({ length: 7 }, (_, dayIndex) => [
      {
        date: formatISO(addDays(startDate, dayIndex), { representation: 'date' }),
        customerId: '',
        serviceType: '',
        hoursWorked: '',
        endWorkHours: '',
        startWorkHours: '',
        overnightStay: false,
        comment: '',
      },
    ]);

    setFormState((prevState) => ({
      ...prevState,
      fromDate: startDate,
      toDate: endDate,
      dailyDetails: newDailyDetails,
    }));
  };

  const filterDays = (index) => () => {
    if (selectedDays.includes(index)) {
      // If the day is already selected, unselect it
      setSelectedDays(prevDays => prevDays.filter(day => day !== index));
    } else {
      // Otherwise, add the day to the selected days
      setSelectedDays(prevDays => [...prevDays, index]);
    }
  };

  const dayOptions = ['Samedi', 'Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi']

  const weekOptions = [
    'Cette semaine',
    'La semaine dernière',
    'La semaine précédente',
  ];

  return (
    <div>
      {
        showCheckmark ? (
          <p>Votre feuille d'heure à été sauvegardé ✅</p>
          ) : (
          <form>
          <div className='featureTitle'>
            <h6>Déclarer votre feuille d'heure</h6>
          </div>
          <div className='timeSheetDate'>
            <label>
              <Dropdown
                options={weekOptions}
                setFunction={handleWeekSelectChange}
                state={selectedWeek}
                label="Sélectionnez une semaine"
              />
            </label>
          </div>

          {formState.fromDate && formState.toDate && (
            <div className='timeSheetDayOptions'>
              {dayOptions.map((day, index) => {
                const isSelected = selectedDays.includes(index);
                return <button key={index}
                onClick={filterDays(index)}
                type="button"
                className={isSelected ? 'selectedDay' : 'notSelectedDay'}
                >{day}</button>
              })}
            </div>
          )}
          {formState.fromDate && formState.toDate && (
          <div className='labelInputInline'>
            <label>Astreinte:</label>
            <input
            type="checkbox"
            checked={formState.onCallDuty}
            onChange={handleOnCallDutyChange}
            className="hidden-checkbox"
            />
          </div>
          )}
          {formState.fromDate && formState.toDate && (
            <div className='timeSheetForm'>
              {(() => {
                const allDays = getDaysBetween(formState.fromDate, formState.toDate).slice(0, 7);
                let daysToDisplay = formState.onCallDuty ? allDays : allDays.filter((_, index) => index !== 0 && index !== 1);
                if (selectedDays.length > 0) {
                  daysToDisplay = allDays.filter((_, index) => selectedDays.includes(index))
                }
                return daysToDisplay.map((day, _) => {
                  const originalIndex = allDays.indexOf(day); // Get the original index from the allDays array
                  // if (selectedDays.length > 0 && !selectedDays.includes(_)) return null
                  return (
                    <div className="timeSheetDay" key={day}>
                      <div>
                        <h5>📅 {capitalizeFirstAndThirdWord(formatDate(day))}</h5>
                      </div>
                      <div className='timeSheetTableContainer'>
                        <table className='timeSheetCustomTable'>
                          <thead>
                            <tr>
                              <th><h6>N° BL / BC</h6></th>
                              <th><h6>Service</h6></th>
                              <th><h6>Absence</h6></th>
                              <th><h6>Hr Début</h6></th>
                              <th><h6>Hr Fin</h6></th>
                              <th><h6>Commentaire</h6></th>
                              <th><h6></h6></th>
                              <th><h6>Nuitée ?</h6></th>
                            </tr>
                          </thead>
                          <tbody>
                            {formState.dailyDetails[originalIndex].map((line, lineIndex) => (
                              <tr key={lineIndex}>
                                <td className='bl'>
                                  <input
                                    type="text"
                                    value={line.customerId}
                                    onChange={handleDailyDetailsChange(originalIndex, lineIndex, 'customerId')}
                                    placeholder="N° BL / BC"
                                  />
                                </td>
                                <td>
                                <select
                                  value={line.serviceType}
                                  onChange={handleDailyDetailsChange(originalIndex, lineIndex, 'serviceType')}
                                >
                                  <option className="dropdownOption" value="-">-</option>
                                  <option value="SAV">SAV</option>
                                  <option value="FS">FS</option>
                                  <option value="PROD">PROD</option>
                                  <option value="ATL">ATL</option>
                                  <option value="FMS">FMS</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  value={line.serviceType}
                                  onChange={handleDailyDetailsChange(originalIndex, lineIndex, 'serviceType')}
                                >
                                  <option className="dropdownOption" value="-">-</option>
                                  <option value="ABS">ABS</option>
                                  <option value="AT">AT</option>
                                  <option value="M">M</option>
                                  <option value="CA">CA</option>
                                </select>
                              </td>

                              <td className='startHour'>
                                <input
                                  type="time"
                                  value={line.startWorkHours}
                                  onChange={handleDailyDetailsChange(originalIndex, lineIndex, 'startWorkHours')}
                                />
                              </td>
                              <td className='endHour'>
                                <input
                                  type="time"
                                  value={line.endWorkHours}
                                  onChange={handleDailyDetailsChange(originalIndex, lineIndex, 'endWorkHours')}
                                />
                              </td>

                              <td className='comm'>
                                <input
                                  type="text"
                                  value={line.comment}
                                  onChange={handleDailyDetailsChange(originalIndex, lineIndex, 'comment')}
                                  placeholder="Commentaire"
                                />
                              </td>
                              {lineIndex !== 0 ? (
                                <td colSpan="4">
                                  <button type="button" className="btnTimeSheeDelete" onClick={() => deleteDailyDetail(line._id, originalIndex, lineIndex)}>
                                    <FontAwesomeIcon  icon={faTrash} size="2x" />
                                  </button>
                                </td>
                              ) : (
                                <td colSpan="4">
                                  <button type="button" className="btnTimeSheeDelete" onClick={() => deleteFirstDaylydetail(line._id, originalIndex, lineIndex)}>
                                    <FontAwesomeIcon  icon={faTrash} size="2x" />
                                  </button>
                                </td>
                              )}
                              <td className='night'>
                                {lineIndex === 0 && (
                                  <input
                                    type="checkbox"
                                    checked={line.overnightStay}
                                    onChange={handleDailyDetailsChange(originalIndex, lineIndex, 'overnightStay')}
                                  />
                                )}
                              </td>
                              </tr>
                            ))}

                            <tr className="addBox">
                              <td colSpan="4">
                                <button type="button" className="btnTimeSheet" onClick={() => handleAddLine(originalIndex)}>
                                <FontAwesomeIcon icon={faPlus} size="2x" />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className='totalDay'>
                          <p>Total journalier: {convertToHoursAndMinutes(calculateTotalHours(originalIndex))}</p>
                          <p style={{ fontSize: "11px" }} >(Les absences, maladies, arrêts de travail ne sont pas comptabilisés)</p>
                        </div>
                    </div>
                  </div>
                  );
                });
              })()}

              <div className='labelInputInlineFlex'>
                <label>Souhaitez vous échanger vos heures supplémentaires contre du temps de repos ?</label>
                  <input
                  type="checkbox"
                  checked={formState.rest}
                  onChange={handleRest}
                  />
              </div>
              <div className='timeSheetTotal'>
                <p>Total nuitée: {formState.overnightStays}</p>
                <p>Total hebdomadaire: {convertToHoursAndMinutes(formState.weeklyTotalHours)}</p>
                <p>Heures supplémentaire: {convertToHoursAndMinutes(formState.overtimeHours)}</p>
                <button onClick={handleSubmit} className="btn" type="submit">Sauvegarder</button>
              </div>
            </div>
          )}
        </form>

        )
      }
    </div>
  );

}

export default TimesheetForm;
