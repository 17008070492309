import logo from '../img/gestion-temps-travail-logo.png'

const Login = ({loginWithRedirect}) => {

  return (
    <div className='loginWrapper'>
      <img className="loginLogo" src={logo} />
      <h2>Gestion du Temps <br />de Travail</h2>
      <p>La solution pour digitaliser vos feuilles d’heure</p>
      <button className="btn loginBtn" onClick={loginWithRedirect}>Se connecter</button>
    </div>
  )
}

export default Login;
