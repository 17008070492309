import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import InfosModal from './InfosModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import logo from '../img/gestion-temps-travail-logo.png'

const Navbar = ({
  isAuthenticated,
  logout,
  user,
  isLoading,
  getAccessTokenSilently,
  navigate,
  handleToggleVerticalNav,
  isScreenSmall,
  admin
}) => {

  const [ modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(!modalIsOpen)
  }


  const handleLoginServer = async () => {
      const userData = {
        auth0ID: user.sub,
        name: user.name,
        email: user.email
      };
  }

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      (async () => {
        await getAccessTokenSilently();
        handleLoginServer();
      })();
    }

  }, [isAuthenticated, isLoading, getAccessTokenSilently]);


  return (
    <div>
      <div className="login">
        {isScreenSmall && (
          <img src={logo}/>
        )}
        {
          modalIsOpen ?
          <div>
            <button className="modalButton" onClick={openModal}>
              <FontAwesomeIcon icon={faBars} />
            </button>
            <InfosModal
              modalIsOpen={modalIsOpen}
              user={user}
              logout={logout}
              isScreenSmall={isScreenSmall}
              admin={admin}
            />
          </div>
          :
          <button className="modalButton" onClick={openModal}>
            <FontAwesomeIcon icon={faBars} />
          </button>

        }
      </div>
    </div>
  );
};

export default Navbar;
