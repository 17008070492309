import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Dropdown from './Dropdown';
import { convertToHoursAndMinutes } from '../utils/timeUtils';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';



const TimesheetPdf = ({formState}) => {
  const { id } = useParams();
  // const [formState, setFormState] = useState({
  //   employeeId: '',
  //   fromDate: '',
  //   toDate: '',
  //   onCallDuty: false,
  //   dailyDetails: Array.from({ length: 7 }, () => [
  //     {
  //       id: '',
  //       date: '',
  //       customerId: '',
  //       serviceType: '',
  //       startWorkHours: '',
  //       endWorkHours: '',
  //       hoursWorked: '',
  //       overnightStay: false,
  //       comment: '',
  //     },
  //   ]),
  //   weeklyTotalHours: 0,
  //   overtimeHours: 0,
  //   overnightStays: 0,
  // });

  function getDaysBetween(startDate, endDate) {
    const days = [];
    const currDate = new Date(startDate);
    const lastDate = new Date(endDate);
    currDate.setHours(0, 0, 0, 0);

    while (currDate <= lastDate) {
      days.push(new Date(currDate));
      currDate.setDate(currDate.getDate() + 1);
    }

    return days;
  }


  const downloadAsPDF = async () => {

    const element = document.getElementById('pdfForm');

    html2canvas(element).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;

        // A4 dimensions in pt
        const a4Width = 595.28;
        const a4Height = 841.89;

        let pdfWidth = a4Width - 80;  // subtracting padding (40*2)
        let pdfHeight = (pdfWidth / imgWidth) * imgHeight;  // scaled by width

        // if height exceeds A4's max height after scaling, scale by height
        if (pdfHeight > a4Height - 80) {  // subtracting padding (40*2)
            pdfHeight = a4Height - 80;  // subtracting padding (40*2)
            pdfWidth = (pdfHeight / imgHeight) * imgWidth;
        }

        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: [a4Width, a4Height],
        });

        pdf.addImage(imgData, 'PNG', 40, 40, pdfWidth, pdfHeight);  // starting at (40, 40) for padding
        pdf.save('download.pdf');
    });
  };




  // Helper function to format a date for display
  function formatDate(date) {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  }
  const calculateTotalHours = (dayIndex) => {
    let totalHours = 0;
    const dailyDetails = formState.dailyDetails[dayIndex];

    for (let i = 0; i < dailyDetails.length; i++) {
      const line = dailyDetails[i];
      const serviceType = line.serviceType;
      if (serviceType !== "ABS" && serviceType !== "AT" && serviceType !== "M") {
        const hoursWorked = Number(line.hoursWorked) || 0;
        totalHours += hoursWorked;
      }
    }

    return totalHours;
  };

  function capitalizeFirstAndThirdWord(str) {
    const words = str.split(' ');

    if (words.length >= 3) {
      words[0] = words[0][0].toUpperCase() + words[0].substring(1);
      words[2] = words[2][0].toUpperCase() + words[2].substring(1);
    }

    return words.join(' ');
  }


  return (
    <div>
    <form id='pdfForm'>
    <div className='timeSheetDate'>
    </div>
      {formState.fromDate && formState.toDate && (
        <div>
          <div style={{textAlign: "center"}}>
            <h6>{formState.employeeName}</h6>
          </div>
          <div className='labelInputInline'>
            <label>Astreinte:</label>
            <p>{formState.onCallDuty ? "Oui" : "Non"}</p>
          </div>
        </div>
      )}
      {formState.fromDate && formState.toDate && (
        <div className='timeSheetForm'>
          {getDaysBetween(formState.fromDate, formState.toDate).slice(0, 7).map((day, dayIndex) => {
            if ((!formState.onCallDuty && dayIndex === 0)) return null;
            if (formState.dailyDetails[dayIndex][0].startWorkHours === "") return null; // Skip rendering if no details
              return (
                      <div className="timeSheetDay" key={day}>
                          <div>
                              <h5>📅 {capitalizeFirstAndThirdWord(formatDate(day))}</h5>
                          </div>
                          <div className='timeSheetTableContainer'>
                              <table className='timeSheetCustomTable'>
                                  <thead>
                                      <tr>
                                          <th><h6>N° BL / BC</h6></th>
                                          <th><h6>Service</h6></th>
                                          <th><h6>Hrs Début</h6></th>
                                          <th><h6>Hrs Fin</h6></th>
                                          <th><h6>Commentaire</h6></th>
                                          <th><h6>Nuitée ?</h6></th>
                                          <th><h6>Validation</h6></th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      {formState.dailyDetails[dayIndex].map((line, lineIndex) => (
                                          <tr key={lineIndex}>
                                              <td className='bl'>
                                                <p>{line.customerId ? line.customerId : ""}</p>
                                              </td>
                                              <td>
                                                <p>{line.serviceType ? line.serviceType : ""}</p>
                                              </td>
                                              <td className='endHour'>
                                                <p>{line.startWorkHours ? line.startWorkHours : ""}</p>
                                              </td>
                                              <td className='endHour'>
                                                <p>{line.endWorkHours ? line.endWorkHours : ""}</p>
                                              </td>
                                              <td className='comm'>
                                                <p>{line.comment ? line.comment : ""}</p>
                                              </td>
                                              <td className='night'>
                                                <p>{line.overnightStay ? "Oui" : "Non"}</p>
                                              </td>
                                              <td className='night'>
                                                <p>{line.validated ? "Oui" : "Non"}</p>
                                              </td>
                                          </tr>

                                      ))}
                                  </tbody>
                              </table>
                              <div className='totalDay'>
                                  <p>Total journalier: {convertToHoursAndMinutes(calculateTotalHours(dayIndex))}</p>
                              </div>
                          </div>
                      </div>
                  )})}
                  <div className='labelInputInlineFlex'>
                  <label>Souhaitez vous échanger vos heures supplémentaires contre du temps de repos ?</label>
                    <p>{formState.rest ? "Oui" : "Non"}</p>
                  </div>
                  <div className='timeSheetTotal'>
                      <p>Total nuitée: {formState.overnightStays}</p>
                      <p>Total hebdomadaire: {convertToHoursAndMinutes(formState.weeklyTotalHours)}</p>
                      <p>Heures supplémentaire: {convertToHoursAndMinutes(formState.overtimeHours)}</p>
                  </div>
              </div>
          )}
      </form>
      <div style={{textAlign: 'center', marginTop: '20px'}}>
        <button className='btn' onClick={() => downloadAsPDF()}>Téléchager votre feuille d'heure</button>
      </div>
  </div>
);

};

export default TimesheetPdf;
