import './App.css';
import { Route, Routes, useNavigate, useLocation} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Navigation from './components/Navigation';
import Navbar from './components/Navbar';
import './css/index.css';
import TimesheetForm from './components/TimesheetForm';
import OnboardingForm from './components/OnboardingForm';
import Login from './components/Login';
import TimesheetView from './components/TimesheetView';
import TimesheetPage from './components/TimesheetPage'
import TimesheetsViewAdmin from './components/TimesheetsViewAdmin';
import LoadingModal from './components/LoadingModal';
import Support from './components/Support';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorThrowingComponent from './components/ErrorThrowingComponent';
import DashboardCEO from './components/DashboardCEO';
import TimesheetPageAdmin from './components/TimesheetPageAdmin';
import TimesheetHeatmap from './components/TimesheetHeatmap';
import ElementsVariables from './components/ElemementsVariables';
import OnboardingCompanyForm from './components/OnboardingCompanyForm';
import Parameters from './components/Parameters';
import SiteReport from './components/SiteReport';

function App() {
  const isScreenSmall = window.innerWidth < 900;
  const isExtraSmall = window.innerWidth < 576;
  const isSmall = window.innerWidth > 576 && window.innerWidth < 768;
  const [isVerticalNavOpen, setVerticalNavOpen] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [username, setUsername] = useState('')

  const handleToggleVerticalNav = () => {
    setVerticalNavOpen(!isVerticalNavOpen);
  };

  const {
    loginWithRedirect,
    isAuthenticated,
    logout,
    user,
    isLoading,
    getAccessTokenSilently
  } = useAuth0();

  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {

    const currentPath = location.pathname;
    // Prevent running the effect on the /login route:
    if (currentPath === '/login') return;

    if (!isLoading && isAuthenticated) {
      if (user) {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users`, { auth0Id: user.sub, ...user })

          .then(response => {
            const { firstName, lastName, phone, team, position, organisationId } = response.data;
            if (!organisationId) {
              navigate('/creation-compte')
            } else if (!firstName || !lastName || !phone || !team) {
              navigate('/onboarding');
            } else {
              setUsername(firstName + " " + lastName)
              if (position === 'Admin') {
                setAdmin(true)
              }
              const currentURL = window.location.pathname;
              navigate(currentURL);
            }
          })
          .catch(error => {
            console.error('Error saving user data:', error);
        });
      }

    } else if (!isLoading && !isAuthenticated) {
      navigate("/login");
    }

  }, [isAuthenticated, isLoading, user]);


  return (
    <div className="App">


    <ErrorBoundary>
    <Routes>

    <Route path="/feuille-heure/:id" element={
      <div className={isScreenSmall ? 'navigationWrapper full-width' : 'navigationWrapper'}>
      {!isScreenSmall || isVerticalNavOpen ? (
        <Navigation admin={admin}/>
        ) : ""}
      <div className='contentWrapper'>
        <Navbar
          loginWithRedirect={loginWithRedirect}
          isAuthenticated={isAuthenticated}
          logout={logout}
          user={user}
          isLoading={isLoading}
          getAccessTokenSilently={getAccessTokenSilently}
          navigate={navigate}
          handleToggleVerticalNav={handleToggleVerticalNav}
          isVerticalNavOpen={isVerticalNavOpen}
          isScreenSmall={isScreenSmall}
        />
        <div className="timeSheetWrapper">
          <TimesheetPage />
        </div>
      </div>
    </div>
    }
    />

    <Route path="/feuille-heure-admin/:id" element={
      <div className={isScreenSmall ? 'navigationWrapper full-width' : 'navigationWrapper'}>
      {!isScreenSmall || isVerticalNavOpen ? (
        <Navigation admin={admin}/>
        ) : ""}
      <div className='contentWrapper'>
        <Navbar
          loginWithRedirect={loginWithRedirect}
          isAuthenticated={isAuthenticated}
          logout={logout}
          user={user}
          isLoading={isLoading}
          getAccessTokenSilently={getAccessTokenSilently}
          navigate={navigate}
          handleToggleVerticalNav={handleToggleVerticalNav}
          isVerticalNavOpen={isVerticalNavOpen}
          isScreenSmall={isScreenSmall}
          username={username}
        />
        <div className="timeSheetWrapper">
          <TimesheetPageAdmin admin={admin}/>
        </div>
      </div>
    </div>
    }
    />

    <Route path="/onboarding" element={
      <div>
        <OnboardingForm user={user} setUsername={setUsername}/>
      </div>
    }
    />

    <Route path="/creation-compte" element={
      <div>
        <OnboardingCompanyForm user={user} setUsername={setUsername}/>
      </div>
    }
    />
    <Route
      path="/"
      element={
        <div>
          <div className={isScreenSmall ? 'navigationWrapper full-width' : 'navigationWrapper'}>
            {!isScreenSmall || isVerticalNavOpen ? (
              <Navigation admin={admin}/>
              ) : ""}
            <div className='contentWrapper'>
              <Navbar
                admin={admin}
                loginWithRedirect={loginWithRedirect}
                isAuthenticated={isAuthenticated}
                logout={logout}
                user={user}
                isLoading={isLoading}
                getAccessTokenSilently={getAccessTokenSilently}
                navigate={navigate}
                handleToggleVerticalNav={handleToggleVerticalNav}
                isVerticalNavOpen={isVerticalNavOpen}
                isScreenSmall={isScreenSmall}
              />
              <div className="timeSheetWrapper">
                <TimesheetForm username={username} />
              </div>
            </div>
          </div>
        </div>
      }
    />

    <Route
      path="/mes-feuilles-heures"
      element={
        <div>
          <div className={isScreenSmall ? 'navigationWrapper full-width' : 'navigationWrapper'}>
            {!isScreenSmall || isVerticalNavOpen ? (
              <Navigation admin={admin}/>
              ) : ""}
            <div className='contentWrapper'>
              <Navbar
                admin={admin}
                loginWithRedirect={loginWithRedirect}
                isAuthenticated={isAuthenticated}
                logout={logout}
                user={user}
                isLoading={isLoading}
                getAccessTokenSilently={getAccessTokenSilently}
                navigate={navigate}
                handleToggleVerticalNav={handleToggleVerticalNav}
                isVerticalNavOpen={isVerticalNavOpen}
                isScreenSmall={isScreenSmall}
              />
              <div className="timeSheetWrapper">
                <TimesheetView
                isExtraSmall={isExtraSmall}
                isSmall={isSmall}
                />
              </div>
            </div>
          </div>
        </div>
      }
    />

    <Route
      path="/liste-feuilles-heures"
      element={
        <div>
          <div className={isScreenSmall ? 'navigationWrapper full-width' : 'navigationWrapper'}>
            {!isScreenSmall || isVerticalNavOpen ? (
              <Navigation admin={admin}/>
              ) : ""}
            <div className='contentWrapper'>
              <Navbar
                admin={admin}
                loginWithRedirect={loginWithRedirect}
                isAuthenticated={isAuthenticated}
                logout={logout}
                user={user}
                isLoading={isLoading}
                getAccessTokenSilently={getAccessTokenSilently}
                navigate={navigate}
                handleToggleVerticalNav={handleToggleVerticalNav}
                isVerticalNavOpen={isVerticalNavOpen}
                isScreenSmall={isScreenSmall}
              />
              <div className="timeSheetWrapper">
                <TimesheetsViewAdmin isScreenSmall={isScreenSmall} admin={admin}/>
              </div>
            </div>
          </div>
        </div>
      }
    />


    <Route path="/login" element={
      <Login loginWithRedirect={loginWithRedirect}/>
    }
    />

    <Route path="/support" element={
      <div>
        <div className={isScreenSmall ? 'navigationWrapper full-width' : 'navigationWrapper'}>
          {!isScreenSmall || isVerticalNavOpen ? (
            <Navigation admin={admin}/>
            ) : ""}
          <div className='contentWrapper'>
            <Navbar
              admin={admin}
              loginWithRedirect={loginWithRedirect}
              isAuthenticated={isAuthenticated}
              logout={logout}
              user={user}
              isLoading={isLoading}
              getAccessTokenSilently={getAccessTokenSilently}
              navigate={navigate}
              handleToggleVerticalNav={handleToggleVerticalNav}
              isVerticalNavOpen={isVerticalNavOpen}
              isScreenSmall={isScreenSmall}
            />
            <div className="timeSheetWrapper">
              <Support />
            </div>
          </div>
        </div>
      </div>
    }
    />

    <Route path="/parametres" element={
      <div>
        <div className={isScreenSmall ? 'navigationWrapper full-width' : 'navigationWrapper'}>
          {!isScreenSmall || isVerticalNavOpen ? (
            <Navigation admin={admin}/>
            ) : ""}
          <div className='contentWrapper'>
            <Navbar
              admin={admin}
              loginWithRedirect={loginWithRedirect}
              isAuthenticated={isAuthenticated}
              logout={logout}
              user={user}
              isLoading={isLoading}
              getAccessTokenSilently={getAccessTokenSilently}
              navigate={navigate}
              handleToggleVerticalNav={handleToggleVerticalNav}
              isVerticalNavOpen={isVerticalNavOpen}
              isScreenSmall={isScreenSmall}
            />
            <div className="timeSheetWrapper">
              <Parameters admin={admin}/>
            </div>
          </div>
        </div>
      </div>
    }
    />

    <Route path="/tableau-de-bord" element={
      <div>
        <div className={isScreenSmall ? 'navigationWrapper full-width' : 'navigationWrapper'}>
          {!isScreenSmall || isVerticalNavOpen ? (
            <Navigation admin={admin}/>
            ) : ""}
          <div className='contentWrapper'>
            <Navbar
              admin={admin}
              loginWithRedirect={loginWithRedirect}
              isAuthenticated={isAuthenticated}
              logout={logout}
              user={user}
              isLoading={isLoading}
              getAccessTokenSilently={getAccessTokenSilently}
              navigate={navigate}
              handleToggleVerticalNav={handleToggleVerticalNav}
              isVerticalNavOpen={isVerticalNavOpen}
              isScreenSmall={isScreenSmall}
            />
            <div className="timeSheetWrapper">
              {<DashboardCEO isScreenSmall={isScreenSmall} admin={admin}/>}
            </div>
          </div>
        </div>
      </div>
    }
    />

    <Route path="/feuilles-validees" element={
      <div>
        <div className={isScreenSmall ? 'navigationWrapper full-width' : 'navigationWrapper'}>
          {!isScreenSmall || isVerticalNavOpen ? (
            <Navigation admin={admin}/>
            ) : ""}
          <div className='contentWrapper'>
            <Navbar
              admin={admin}
              loginWithRedirect={loginWithRedirect}
              isAuthenticated={isAuthenticated}
              logout={logout}
              user={user}
              isLoading={isLoading}
              getAccessTokenSilently={getAccessTokenSilently}
              navigate={navigate}
              handleToggleVerticalNav={handleToggleVerticalNav}
              isVerticalNavOpen={isVerticalNavOpen}
              isScreenSmall={isScreenSmall}
            />
            <div className="timeSheetWrapper">
              {<TimesheetHeatmap isScreenSmall={isScreenSmall} admin={admin}/>}
            </div>
          </div>
        </div>
      </div>
    }
    />

    <Route path="/elements-variables" element={
      <div>
        <div className={isScreenSmall ? 'navigationWrapper full-width' : 'navigationWrapper'}>
          {!isScreenSmall || isVerticalNavOpen ? (
            <Navigation admin={admin} />
            ) : ""}
          <div className='contentWrapper'>
            <Navbar
              admin={admin}
              loginWithRedirect={loginWithRedirect}
              isAuthenticated={isAuthenticated}
              logout={logout}
              user={user}
              isLoading={isLoading}
              getAccessTokenSilently={getAccessTokenSilently}
              navigate={navigate}
              handleToggleVerticalNav={handleToggleVerticalNav}
              isVerticalNavOpen={isVerticalNavOpen}
              isScreenSmall={isScreenSmall}
            />
            <div className="timeSheetWrapper">
              {<ElementsVariables isScreenSmall={isScreenSmall} admin={admin}/>}
            </div>
          </div>
        </div>
      </div>
    }
    />

    <Route path="/rapport-chantier" element={
      <div>
        <div className={isScreenSmall ? 'navigationWrapper full-width' : 'navigationWrapper'}>
          {!isScreenSmall || isVerticalNavOpen ? (
            <Navigation admin={admin} />
            ) : ""}
          <div className='contentWrapper'>
            <Navbar
              admin={admin}
              loginWithRedirect={loginWithRedirect}
              isAuthenticated={isAuthenticated}
              logout={logout}
              user={user}
              isLoading={isLoading}
              getAccessTokenSilently={getAccessTokenSilently}
              navigate={navigate}
              handleToggleVerticalNav={handleToggleVerticalNav}
              isVerticalNavOpen={isVerticalNavOpen}
              isScreenSmall={isScreenSmall}
            />
            <div className="timeSheetWrapper">
              <SiteReport />
            </div>
          </div>
        </div>
      </div>
    }
    />

    </Routes>
    </ErrorBoundary>
    </div>
  );
}

export default App;
