import React, { useState, useEffect} from 'react';
import axios from 'axios';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useAuth0 } from '@auth0/auth0-react';
import ElementsVariablesPdf from './ElementsVariablesPdf';



const ElementsVariables = ({isScreenSmall, admin}) => {
  const [filter, setFilter] = useState({
    month: ''
  });
  const [data, setData] = useState([]);
  const [_id, set_Id] = useState();
  const { user, isLoading } = useAuth0();


  const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]

  const handleFilterChange = (e) => {
    setFilter({
        ...filter,
        [e.target.name]: e.target.value
    });
  }

  function downloadAsPDF() {
    const element = document.getElementById('pdfForm');

    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // A4 dimensions in pt
      const a4Width = 841.89;
      const a4Height = 595.28;

      let pdfWidth = a4Width - 80;  // subtracting padding (40*2)
      let pdfHeight = (pdfWidth / imgWidth) * imgHeight;  // scaled by width

      // if height exceeds A4's max height after scaling, scale by height
      if (pdfHeight > a4Height - 80) {  // subtracting padding (40*2)
          pdfHeight = a4Height - 80;  // subtracting padding (40*2)
          pdfWidth = (pdfHeight / imgHeight) * imgWidth;
      }

      const pdf = new jsPDF({
          orientation: 'landscape',
          unit: 'pt',
          format: [a4Width, a4Height],
      });

      pdf.addImage(imgData, 'PNG', 10, 40, pdfWidth, pdfHeight);  // starting at (40, 40) for padding
      pdf.save('download.pdf');
  });

}



  const updateEmployeeData = async () => {
      try {
          if (_id) {
            data['_id'] = _id;
          }
          // Send request to Node.js backend
          const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/elementsVariable/update-elements-variable`, data);
          window.location.reload();
      } catch (error) {
          console.error('Error updating employee data:', error);
      }
  };


  const calculateAbsenceTotalsForEmployee = (absenceObj) => {
    let tAbs = 0;
    let tCa = 0;

    ['m', 'at', 'abs'].forEach(serviceType => {
      (absenceObj[serviceType] || []).forEach(detail => {
        tAbs += parseFloat(detail.hours) || 0;  // safely add hours, converting to number and handling potential NaN
      });
    });

    (absenceObj['ca'] || []).forEach(detail => {
      tCa += parseFloat(detail.hours) || 0;  // safely add hours
    });

    absenceObj.tAbs = tAbs;
    absenceObj.tCa = tCa;
  }

  const handleInputChange = (e, name, field) => {
    const newValue = e.target.value;

    // Cloner l'objet data pour éviter de modifier l'état directement
    const newData = { ...data };
    newData[name][field] = newValue;

    setData(newData);
  };


  const handleInputChangeAbsence = (e, name, mainField, subField, index) => {
    const newValue = e.target.type === 'number' ? parseFloat(e.target.value) : e.target.value;
    // Clone the data object to avoid directly modifying the state
    const newData = { ...data };

    newData[name][mainField][subField][index].hours = newValue;
    setData(newData);
    calculateAbsenceTotalsForEmployee(data[name].absence);
  };




  function formatDateToHumanReadable(inputDate) {
    const date = new Date(inputDate);
    const day = date.getUTCDate().toString().padStart(2, '0'); // Get the day and ensure it's 2 digits

    const months = ["Janv", "Fév", "Mar", "Avr", "Mai", "Juin", "Juill", "Août", "Sept", "Oct", "Nov", "Déc"];
    const monthName = months[date.getUTCMonth()]; // Get the month name

    return `${day}-${monthName}`;
}


  const deleteElementsVariables = async () => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/elementsVariable/delete-elements-variable/${_id}`);
      window.location.reload();
    } catch (error) {
      console.log(error)
    }
  }

  const fetchData = async (filters) => {
    if (filter.month) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/elementsVariable/elements-variable`, { params: {filters: filter, user: user} });

        if (response.data.overtimeData) {
          setData(response.data.overtimeData)
        } else {
          setData(response.data)
        }

        if (response.data._id) {
          set_Id(response.data._id);
        }

      } catch (error) {
        throw error;
      }

    }
  }

  useEffect(() => {

  }, [admin]);

  if (!admin) {
    return <div><h3>Chargement ...</h3></div>;
  }

  return (

    <div>
      {isScreenSmall ? (
        <p>Cette page n'est disponible que version ordinateur</p>
      ) : (
        <div className='elementsVariables'>
        <label>
          <select name="month" value={filter.month} onChange={handleFilterChange}>
            <option value="">Sélectionner un mois</option>
            {months.map((month) => (
                <option key={month} value={month}>{month}</option>
            ))}
          </select>
        </label>
        <button className="btn" onClick={fetchData}>Rechercher</button>
        <div>
        {Object.keys(data).length > 0 && (
          <h3>Tableau des éléments variables</h3>
        )}
          {filter.month && (
            <p>Paie du mois de : {filter.month} 2023</p>
          )}
          {Object.keys(data).length > 0 && (
            <div className='elementsGridWrapper'>
            <div className='elementsGridHeaderWrapper'>
              <div className='elementsGridHeader1'>
              <p>Salarié</p>
              </div>
              <div className='elementsGridHeader2'>
                <div className='elementsGridHeader2Row1'>
                  <p>Nb hrs supp / J de repos</p>
                </div>
                <div className='elementsGridHeader2Row2'>
                  <div className='elementsGridHeader2Row2Col1'>
                    <p>Hrs a 25%</p>
                  </div>

                  <div className='elementsGridHeader2Row2Col2'>
                    <p>Hrs a 50%</p>
                  </div>

                  <div className='elementsGridHeader2Row2Col3'>
                    <p>Jours de repos</p>
                  </div>

                </div>

              </div>
              <div className='elementsGridHeader3'>
                <div className='elementsGridHeader3Row1'>
                  <p>Absences, Maladie, AT</p>
                </div>
                <div className='elementsGridHeader3Row2'>
                  <div className='elementsGridHeader3Row2Col1'>
                    <p>Motif</p>
                  </div>

                  <div className='elementsGridHeader3Row2Col2'>
                    <p>Date</p>
                  </div>

                  <div className='elementsGridHeader3Row2Col3'>
                    <p>Nombre hrs</p>
                  </div>
                </div>
              </div>
              <div className='elementsGridHeader4'>
                <div className='elementsGridHeader4Row1'>
                  <p>Dates des congés payés</p>
                </div>
                <div className='elementsGridHeader4Row2'>
                  <div className='elementsGridHeader4Row2Col1'>
                    <p>Date</p>
                  </div>

                  <div className='elementsGridHeader4Row2Col2'>
                    <p>Nombre hrs</p>
                  </div>
                </div>
              </div>
              <div className='elementsGridHeader5'>
                <div className='elementsGridHeader5Row1'>
                  <p>Divers: primes, accomptes, commissions, astreintes, ...</p>
                </div>
                <div className='elementsGridHeader5Row2'>
                  <div className='elementsGridHeader5Row2Col1'>
                    <p>Motif</p>
                  </div>

                  <div className='elementsGridHeader5Row2Col2'>
                    <p>Montant</p>
                  </div>
                </div>
              </div>
            </div>
              {Object.keys(data).map((name) => (
                <div key={name} className='elementsGridContentWrapper'>
                  <div className='elementsGridContent1'>
                    <p>{name}</p>
                  </div>
                  <div className='elementsGridContent2'>
                    <div className='elementsGridContent2Col1'>
                      <input
                        value={data[name].overtimeHours25}
                        onChange={(e) => handleInputChange(e, name, 'overtimeHours25')}
                        type='number'
                      />
                    </div>

                    <div className='elementsGridContent2Col2'>
                      <input
                      value={data[name].overtimeHours50}
                      onChange={(e) => handleInputChange(e, name, 'overtimeHours50')}
                      type='number'
                      />
                    </div>

                    <div className='elementsGridContent2Col3'>
                      <input
                      value={data[name].dayOff}
                      onChange={(e) => handleInputChange(e, name, 'dayOff')}
                      type='number'
                      />
                    </div>
                  </div>
                  <div className='elementsGridContent3'>

                    {Object.keys(data[name].absence).map((motif, index) => {
                      if (['m', 'at', 'abs'].includes(motif)) {
                        return data[name].absence[motif].map((motifItem, itemIndex) => (
                          <div key={itemIndex} className='elementsGridHeader3Line'>
                            <div className='elementsGridContent3LineCell'>
                              <p>{motif.toUpperCase()}</p>
                            </div>
                            <div>
                              <p>{formatDateToHumanReadable(motifItem.date)}</p>
                            </div>
                            <div>
                              <input
                                value={motifItem.hours}
                                onChange={e => handleInputChangeAbsence(e, name, 'absence', motif, itemIndex)}
                                type='number'
                              />
                            </div>
                          </div>
                        ));
                      }
                      return null;
                    })}
                    <div className='elementsGridContent3LineTotal'>
                      <p>Total: {data[name].absence['tAbs']}h</p>
                    </div>

                  </div>
                    <div className='elementsGridContent4'>
                      {Object.keys(data[name].absence).map((motif, index) => {
                        if (['ca'].includes(motif)) {
                          return data[name].absence[motif].map((motifItem, itemIndex) => (
                            <div key={itemIndex} className='elementsGridHeader3Line'>
                              <div className='elementsGridContent3LineCell'>
                                <p>{motif.toUpperCase()}</p>
                              </div>
                              <div>
                                <p>{formatDateToHumanReadable(motifItem.date)}</p>
                              </div>
                              <div>
                                <input
                                  value={motifItem.hours}
                                  onChange={e => handleInputChangeAbsence(e, name, 'absence', motif, itemIndex)}
                                  type='number'
                                />
                              </div>
                            </div>
                          ));
                        }
                        return null;
                      })}
                      <div className='elementsGridContent3LineTotal'>
                        <p>Total: {data[name].absence['tCa']}h</p>
                      </div>
                  </div>
                  <div className='elementsGridContent5'>
                    <div className='elementsGridContent5Cell1'>
                      <textarea
                          value={data[name].reason}
                          onChange={(e) => handleInputChange(e, name, 'reason')}
                          rows="4"  // you can specify the initial number of visible lines
                          cols="50" // you can specify the width in average character widths
                      ></textarea>

                    </div>
                    <div className='elementsGridContent5Cell2'>
                      <input
                        value={data[name].reasonAmount}
                        onChange={(e) => handleInputChange(e, name, 'reasonAmount')}
                        type='number'
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          </div>
          {Object.keys(data).length > 0 && (
            <div className='elementsBtns'>
              <button className='btn btnUpdate' onClick={() => updateEmployeeData()}>Sauvegarder</button>
              <button className='btn btnUpdate' onClick={() => downloadAsPDF()}>Téléchager</button>
              <button className='btn btnUpdate Red' onClick={() => deleteElementsVariables()}>Supprimer</button>
              <div style={{ position: 'absolute', left: '-9999px', zIndex: -1 }}>
                <ElementsVariablesPdf data={data} filter={filter} />
              </div>
            </div>
          )}

      </div>
      )}
    </div>
  )
}

export default ElementsVariables;
