import React, { useState, useEffect } from 'react';

function MultiSelect({ items, setFormState, index, formState }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);

  useEffect(() => {
    setFilteredItems(
      items.filter(item =>
        item.toLowerCase().includes(searchTerm.toLowerCase()) &&
        !formState.dailyDetailsSiteReports[index][0].employees.includes(item)
      )
    );
  }, [searchTerm, items, formState, index]);


  const handleSelect = (item) => {

    const newDailyDetailsSiteReports = formState.dailyDetailsSiteReports
    if (!newDailyDetailsSiteReports[index][0].employees.includes(item)) {
      newDailyDetailsSiteReports[index][0].employees = [...newDailyDetailsSiteReports[index][0].employees, item]
      setFormState(prevState => ({
        ...prevState,
        dailyDetailsSiteReports: newDailyDetailsSiteReports
      }));
      setSearchTerm(''); // Clear the input after selecting an item
    }
  };

  const handleDeselect = (item) => {
    const newDailyDetailsSiteReports = [...formState.dailyDetailsSiteReports];

    // Check if the item exists in the employees array
    if (newDailyDetailsSiteReports[index][0].employees.includes(item)) {
      // Filter out the item from the employees array
      newDailyDetailsSiteReports[index][0].employees = newDailyDetailsSiteReports[index][0].employees.filter(i => i !== item);
    }

    // Update the formState
    setFormState(prevState => ({
      ...prevState,
      dailyDetailsSiteReports: newDailyDetailsSiteReports
    }));
  };


  return (
    <div className='siteReportMultiSelect'>
      <div>
        <input
          type="text"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          placeholder="Rechercher ..."
        />
        {searchTerm && (
          <div className='searchName'>
            {filteredItems.map((item, index) => (
              <button key={index} onClick={() => handleSelect(item)}>
                {item}
              </button>
            ))}
          </div>
        )}
      </div>
      <div className='listName'>
          {formState.dailyDetailsSiteReports[index][0].employees.map((item, index) => (
              <button
                key={index}
                onClick={() => handleDeselect(item)}
              >
                {item}
              </button>
          ))}
        </div>

    </div>
  );
}


export default MultiSelect;

// export default function App() {
//   const items = ['Apple', 'Banana', 'Cherry', 'Date', 'Fig', 'Grape', 'Kiwi'];
// ={items} />;
// }
