import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

function Parameters({admin}) {
  const { user, isLoading } = useAuth0();
  const [users, setUsers] = useState([]);
  const [newUsers, setNewUsers] = useState([{ email: '', role: 'user' }]);
  const roles = ['Admin', 'Utilisateur'];


  const isValidEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };


  const fetchUsers = async () => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/api/companyUsers`;
    try {
      const response = await axios.get(url, { params: user });
      setUsers(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const addUsers = async () => {
    if (newUsers.some(newUser => !isValidEmail(newUser.email))) {
      alert("Adresse e-mail invalide.");
      return;
    }
    const url = `${process.env.REACT_APP_API_BASE_URL}/api/addCompanyUsers`;
    try {
      const response = await axios.post(url, { admin: user, users: newUsers }); // user object from useAuth0()
      if (response.status === 200) {
        fetchUsers();
        setNewUsers([{ email: '', role: 'user' }]);
      }
    } catch (err) {
      if (err.response && err.response.data === 'Invalid email') {
        alert("Adresse e-mail invalide.");
      } else {
        console.log(err);
      }
    }
  };

  const deleteUser = async (userEmail) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/api/deleteCompanyUser`;
    try {
      const response = await axios.post(url, { admin: user, userEmail }); // user object from useAuth0()
      if (response.status === 200) {
        fetchUsers(); // refresh the user list
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateRole = async (userEmail, newRole) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/api/updateCompanyUserRole`;
    try {
      const response = await axios.post(url, { admin: user, userEmail, newRole }); // user object from useAuth0()
      if (response.status === 200) {
        fetchUsers(); // refresh the user list
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addNewUserInput = () => {
    setNewUsers([...newUsers, { email: '', role: 'user' }]);
  };

  const removeNewUserInput = (index) => {
    const updatedNewUsers = [...newUsers];
    updatedNewUsers.splice(index, 1);
    setNewUsers(updatedNewUsers);
  };

  const handleNewUserChange = (index, key, value) => {
    const updatedNewUsers = [...newUsers];
    updatedNewUsers[index][key] = value;
    setNewUsers(updatedNewUsers);
  };

  useEffect(() => {

    if (!isLoading) {
      fetchUsers();
    }
  }, [isLoading, admin]);

  // if (!admin) {
  //   return <h3>Chargement ...</h3>
  // }


  return (
    <div className="supportView">
      <h3>Gestion des utilisateurs</h3>

      {/* Add New Users */}
      <h4>Ajouter un utilisateur</h4>
      {newUsers.map((newUser, index) => (
        <div className='addUserGridWrapper' key={index}>
          <input
            type="text"
            placeholder="Email"
            value={newUser.email}
            onChange={(e) => handleNewUserChange(index, 'email', e.target.value)}
          />
          <select
            value={newUser.role}
            onChange={(e) => handleNewUserChange(index, 'role', e.target.value)}
          >
            {roles.map((role, i) => <option key={i} value={role}>{role}</option>)}
          </select>
          <button className='btnTimeSheeDelete' onClick={() => removeNewUserInput(index)}>
            <FontAwesomeIcon icon={faTrash} size="2x" />
          </button>
        </div>
      ))}

      <div className='addUserButtonGridWrapper'>
        <button className='buttonPlus' onClick={addNewUserInput}>
          <FontAwesomeIcon icon={faPlus} size="2x" />
        </button>
        <button className='btn' onClick={addUsers}>Enregistrer</button>
      </div>

      {/* List Existing Users */}
      <h4>Supprimer / Mettre à jour le rôle d'un utilisateur</h4>
      {users && (
        users.map((user, index) =>
          <React.Fragment key={index}>
            <div className='addUserGridWrapper'>
              <p>{user.email}</p>
              <select
                value={user.role}
                onChange={(e) => updateRole(user.email, e.target.value)}
              >
                <option value="Utilisateur">Utilisateur</option>
                <option value="Admin">Admin</option>
              </select>
              <button className='btnTimeSheeDelete' onClick={() => deleteUser(user.email)}>
                <FontAwesomeIcon icon={faTrash} size="2x" />
              </button>
            </div>
          </React.Fragment>
        )
      )}
    </div>
  );
}

export default Parameters;
