export function convertToHoursAndMinutes(decimalHours) {
  const hours = Math.floor(decimalHours);
  const minutes = Math.round((decimalHours - hours) * 60);
  return `${hours}h${String(minutes).padStart(2, '0')}`;
}


export function formatDate(date) {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString(undefined, options);
}

export function getDaysBetween(startDate, endDate) {
  const days = [];
  const currDate = new Date(startDate);
  const lastDate = new Date(endDate);
  currDate.setHours(0, 0, 0, 0);

  while (currDate <= lastDate) {
    days.push(new Date(currDate));
    currDate.setDate(currDate.getDate() + 1);
  }

  return days;
}

export function formatDate2(dateString) {
  const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

  const date = new Date(dateString);
  const dayName = days[date.getDay()];
  const dayNumber = date.getDate();
  const monthName = months[date.getMonth()];

  return `${dayName} ${dayNumber} ${monthName}`;
}
