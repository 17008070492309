import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import Dropdown from './Dropdown';
import { addDays, subDays, formatISO, startOfWeek, subWeeks, isValid } from 'date-fns'; // Import necessary functions from date-fns
import { capitalizeFirstAndThirdWord } from '../utils/charactersConverter';
import { formatDate2 } from '../utils/timeUtils';
import MultiSelect from './Multiselect';



function SiteReport({admin}) {
  const { user, isLoading } = useAuth0();
  const [selectedWeek, setSelectedWeek] = useState('');
  const [selectedDays, setSelectedDays] = useState([]); // New state for selected days

  const [formState, setFormState] = useState({
    employeeId: 'dede',
    employeeName: '',
    vehicule: '',
    fromDate: '',
    toDate: '',
    dailyDetailsSiteReports: Array.from({ length: 5 }, () => ({
      _id: '',
      date: '',
      constructionSite: '',
      travelTime: '',
      hoursWorked: '',
      comment: '',
      restaurant: '',
      countMeal: 0,
      employees: [],
    })),
    weeklyTotalHours: 0,
    overtimeHours: 0,
    validated: false,
    organisationId: ''
  });


  const handleVehiculeChange = (event) => {

    setFormState(prevState => ({
      ...prevState,
      vehicule: event.target.value
    }));
  }

  const handleTravelTimeChange = (index) => (event) => {
    console.log(event.target.value)
    const newDailyDetailsSiteReports = formState.dailyDetailsSiteReports
    newDailyDetailsSiteReports[index].travelTime = event.target.value
    console.log(newDailyDetailsSiteReports)

    setFormState(prevState => ({
      ...prevState,
      dailyDetailsSiteReports: newDailyDetailsSiteReports
    }));
  }

  const handleDailyDetailsChange = (index, property) => (event) => {
    setFormState(prevState => {
      const updatedReports = [...prevState.dailyDetailsSiteReports];
      updatedReports[index] = {
        ...updatedReports[index],
        [property]: event.target.value
      };
      return { ...prevState, dailyDetailsSiteReports: updatedReports };
    });
  };


  const handleConstructionSiteChange = (index) => (event) => {

    const newDailyDetailsSiteReports = formState.dailyDetailsSiteReports
    newDailyDetailsSiteReports[index].constructionSite = event.target.value
    console.log(newDailyDetailsSiteReports)
    setFormState(prevState => ({
      ...prevState,
      dailyDetailsSiteReports: newDailyDetailsSiteReports
    }));
  }


  const handleSubmit = async () => {

    const url = `${process.env.REACT_APP_API_BASE_URL}/api/siteReports/createSiteReports`;
    const response = axios.post(url, {params: formState, user: user})
  }


  const filterDays = (index) => () => {
    if (selectedDays.includes(index)) {
      // If the day is already selected, unselect it
      setSelectedDays(prevDays => prevDays.filter(day => day !== index));
    } else {
      // Otherwise, add the day to the selected days
      setSelectedDays(prevDays => [...prevDays, index]);
    }
  };

  useEffect(() => {

    if (!isLoading) {

    }
  }, [isLoading, admin]);

  const handleWeekSelectChange = (option) => {
    const selectedWeek = option;
    let startDate, endDate;


    if (option === 'Cette semaine') {
      startDate = subDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 0); // Saturday of the last week
      endDate = addDays(startOfWeek(new Date(), { weekStartsOn: 0 }), 5);   // Friday of the current week
    } else if (option === 'La semaine dernière') {
      startDate = subDays(startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }), 0); // Saturday of the week before the previous week
      endDate = addDays(startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 0 }), 5);   // Friday of the previous week
    } else if (option === 'La semaine précédente') {
      startDate = subDays(startOfWeek(subWeeks(new Date(), 2), { weekStartsOn: 1 }), 0); // Saturday of the week two weeks ago
      endDate = addDays(startOfWeek(subWeeks(new Date(), 2), { weekStartsOn: 0 }), 5);   // Friday of the week before the previous week
    }


    // Convert both to Date objects if they are not already
    const startDateObj = new Date(startDate);
    const formDateObj = new Date(formState.fromDate);


    // // Compare date values
    // if (startDateObj.getTime() === formDateObj.getTime()) {
    //     fetchTimesheetData();
    // }

    setSelectedWeek(selectedWeek);

    const newDailyDetailsSiteReports = Array.from({ length: 5 }, (_, dayIndex) => [
      {
        _id: '',
        date: formatISO(addDays(startDate, dayIndex), { representation: 'date' }),
        constructionSite: '',
        travelTime: '',
        hoursWorked: '',
        comment: '',
        restaurant: '',
        countMeal: 0,
        employees: [],
      },
    ]);

    setFormState((prevState) => ({
      ...prevState,
      fromDate: startDate,
      toDate: endDate,
      dailyDetailsSiteReports: newDailyDetailsSiteReports,
    }));

  };

  const dayOptions = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi']


  const weekOptions = [
    'Cette semaine',
    'La semaine dernière',
    'La semaine précédente',
  ];

  const employees = [
    'Pierre', 'Paul', 'Jacques', 'Denis', 'Lucas', 'Martin', 'Louis', 'Nicolas', 'Jean', 'François',
    'Claude', 'Philippe', 'Alain', 'René', 'Marcel', 'André', 'Robert', 'Patrick', 'Daniel', 'Michel',
    'Laurent', 'Sébastien', 'Christophe', 'Frédéric', 'Julien', 'Benoît', 'Marc', 'Vincent', 'Antoine', 'Thierry'
  ];


  return (
    <div className="siteReportView">
      <div className='featureTitle'>
        <h6>Déclarer votre rapport de chantier</h6>
      </div>
      <div className='siteReportSheetDate'>
      <label>
        <Dropdown
          options={weekOptions}
          setFunction={handleWeekSelectChange}
          state={selectedWeek}
          label="Sélectionnez une semaine"
        />
      </label>
    </div>
  {formState.fromDate && formState.toDate && (
    <div className='siteReportDayOptions'>
      {dayOptions.map((day, index) => {
        const isSelected = selectedDays.includes(index);
        return <button key={index}
        onClick={filterDays(index)}
        className={isSelected ? 'selectedDay' : 'notSelectedDay'}
        >{day}</button>
      })}
    </div>
  )}
    {formState.fromDate && formState.toDate && (
      <div className='siteReportVehicule'>
        <p>Véhicule</p>
        <input
          type="text"
          value={formState.vehicule}
          onChange={handleVehiculeChange}
          placeholder="N°"
        />
      </div>
    )}
    {formState.fromDate && formState.toDate && (
      formState.dailyDetailsSiteReports.map((dailyDetails, index) => {
        if (selectedDays.length > 0 && !selectedDays.includes(index)) return null;
        return (
          <div key={index} className='siteReportWrapper'>
            <div className='siteReportGrid'>
              <div className='siteReportFromDate'>
                <p>📅 {formatDate2(dailyDetails[0].date)}</p>
              </div>
              <div className='siteReportTravelTime'>
                <p>Temps de trajet</p>
                <input
                  type="time"
                  value={dailyDetails.travelTime}
                  onChange={handleDailyDetailsChange(index, 'travelTime')}
                />

              </div>
              <div className='siteReportConstructionSite'>
                <p>Adresse</p>
                <input
                  type="text"
                  value={dailyDetails.constructionSite}
                  onChange={handleDailyDetailsChange(index, 'constructionSite')}
                  placeholder="Ville, ..."
                />
              </div>
              <div className='siteReportComment'>
                <p>Nature des travaux</p>
                <input
                type="text"
                value={dailyDetails.comment}
                onChange={handleDailyDetailsChange(index, 'comment')}
                placeholder="Chantier ..."
                />
              </div>
              <div className='siteReportEmployees'>
                <p>Salariés</p>
                <MultiSelect items={employees}
                setFormState={setFormState}
                index={index}
                formState={formState} />
              </div>
              <div className='siteReportHoursWorked'>
                <p>Heures travaillées</p>
                <input
                type="number"
                value={dailyDetails.hoursWorked}
                onChange={handleDailyDetailsChange(index, 'hoursWorked')}
                placeholder="Hrs..."
                />
              </div>
              <div className='siteReportHoursWorked'>
                <p>Nombre de repas </p>
                <input
                type="number"
                value={dailyDetails.countMeal}
                onChange={handleDailyDetailsChange(index, 'countMeal')}
                placeholder="Nbr..."
                />
              </div>
              <div className='siteReportRestaurant'>
                <p>Restaurant </p>
                <input
                type="text"
                value={dailyDetails.restaurant}
                onChange={handleDailyDetailsChange(index, 'restaurant')}
                placeholder="Nom..."
                />
              </div>

            </div>
          </div>
        )

      })
    )}
    {formState.fromDate && formState.toDate && (
      <div className='siteReportSave'>
        <button className='btn' onClick={handleSubmit}>Sauvegarder</button>
      </div>
    )}
    </div>
  );
}

export default SiteReport;
