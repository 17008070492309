import React from 'react';
import { convertToHoursAndMinutes } from '../utils/timeUtils';


function DashboardCeoTable ({ dataTable }) {
    // Extract all the unique keys from the dataTable items except the predefined ones
    const allKeys = [
        ...new Set(
            dataTable.flatMap(item => Object.keys(item))
        )
    ].filter(key => !['name', 'fromDate', 'overtimeHours', 'weeklyTotalHours'].includes(key));

    return (
        <div className="dashboardCeoTable">
            <p>Feuilles d'heures, totaux hebomadaire</p>
            <table>
                <thead>
                    <tr>
                        <th>Salarié</th>
                        <th>Date de début</th>
                        <th>Hrs supplémentaire</th>
                        <th>Hrs semaine</th>
                        {allKeys.map(key => <th key={key}>{key}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {dataTable.map((item, index) => (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td>{new Date(item.fromDate).toLocaleDateString()}</td>
                            <td>{convertToHoursAndMinutes(item.overtimeHours)}</td>
                            <td>{convertToHoursAndMinutes(item.weeklyTotalHours)}</td>
                            {allKeys.map(key => <td key={key}>{convertToHoursAndMinutes(item[key] || 0)}</td>)}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default DashboardCeoTable;
