import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Dropdown from './Dropdown';
import { convertToHoursAndMinutes } from '../utils/timeUtils';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';



const ElementsVariablesPdf = ({data, filter}) => {
  const { id } = useParams();

  function formatDateToHumanReadable(inputDate) {
    const date = new Date(inputDate);
    const day = date.getUTCDate().toString().padStart(2, '0'); // Get the day and ensure it's 2 digits

    const months = ["Janv", "Fév", "Mar", "Avr", "Mai", "Juin", "Juill", "Août", "Sept", "Oct", "Nov", "Déc"];
    const monthName = months[date.getUTCMonth()]; // Get the month name

    return `${day}-${monthName}`;
}

  const downloadAsPDF = async () => {

    const element = document.getElementById('pdfForm');

    html2canvas(element).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;

        // A4 dimensions in pt
        const a4Width = 595.28;
        const a4Height = 841.89;

        let pdfWidth = a4Width - 80;  // subtracting padding (40*2)
        let pdfHeight = (pdfWidth / imgWidth) * imgHeight;  // scaled by width

        // if height exceeds A4's max height after scaling, scale by height
        if (pdfHeight > a4Height - 80) {  // subtracting padding (40*2)
            pdfHeight = a4Height - 80;  // subtracting padding (40*2)
            pdfWidth = (pdfHeight / imgHeight) * imgWidth;
        }

        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: [a4Width, a4Height],
        });

        pdf.addImage(imgData, 'PNG', 40, 40, pdfWidth, pdfHeight);  // starting at (40, 40) for padding
        pdf.save('download.pdf');
    });
  };



  return (
    <div>
        <div className='elementsVariables'>
        <div id="pdfForm">
        {Object.keys(data).length > 0 && (
          <h3>Tableau des éléments variables</h3>
        )}
          {filter.month && (
            <p>Paie du mois de : {filter.month} 2023</p>
          )}
          {Object.keys(data).length > 0 && (
            <div className='elementsGridWrapper'>
            <div className='elementsGridHeaderWrapper'>
              <div className='elementsGridHeader1'>
              <p>Salarié</p>
              </div>
              <div className='elementsGridHeader2'>
                <div className='elementsGridHeader2Row1'>
                  <p>Nb hrs supp / J de repos</p>
                </div>
                <div className='elementsGridHeader2Row2'>
                  <div className='elementsGridHeader2Row2Col1'>
                    <p>Hrs a 25%</p>
                  </div>

                  <div className='elementsGridHeader2Row2Col2'>
                    <p>Hrs a 50%</p>
                  </div>

                  <div className='elementsGridHeader2Row2Col3'>
                    <p>Jours de repos</p>
                  </div>

                </div>

              </div>
              <div className='elementsGridHeader3'>
                <div className='elementsGridHeader3Row1'>
                  <p>Absences, Maladie, AT</p>
                </div>
                <div className='elementsGridHeader3Row2'>
                  <div className='elementsGridHeader3Row2Col1'>
                    <p>Motif</p>
                  </div>

                  <div className='elementsGridHeader3Row2Col2'>
                    <p>Date</p>
                  </div>

                  <div className='elementsGridHeader3Row2Col3'>
                    <p>Nombre hrs</p>
                  </div>
                </div>
              </div>
              <div className='elementsGridHeader4'>
                <div className='elementsGridHeader4Row1'>
                  <p>Dates des congés payés</p>
                </div>
                <div className='elementsGridHeader4Row2'>
                  <div className='elementsGridHeader4Row2Col1'>
                    <p>Date</p>
                  </div>

                  <div className='elementsGridHeader4Row2Col2'>
                    <p>Nombre hrs</p>
                  </div>
                </div>
              </div>
              <div className='elementsGridHeader5'>
                <div className='elementsGridHeader5Row1'>
                  <p>Divers: primes, accomptes, commissions, astreintes, ...</p>
                </div>
                <div className='elementsGridHeader5Row2'>
                  <div className='elementsGridHeader5Row2Col1'>
                    <p>Motif</p>
                  </div>

                  <div className='elementsGridHeader5Row2Col2'>
                    <p>Montant</p>
                  </div>
                </div>
              </div>
            </div>
              {Object.keys(data).map((name) => (
                <div key={name} className='elementsGridContentWrapper'>
                  <div className='elementsGridContent1'>
                    <p>{name}</p>
                  </div>
                  <div className='elementsGridContent2'>
                    <div className='elementsGridContent2Col1'>
                      <p>{data[name].overtimeHours25}</p>
                    </div>

                    <div className='elementsGridContent2Col2'>
                      <p>{data[name].overtimeHours50}</p>
                    </div>

                    <div className='elementsGridContent2Col3'>
                      <p>{data[name].dayOff}</p>
                    </div>
                  </div>
                  <div className='elementsGridContent3'>

                    {Object.keys(data[name].absence).map((motif, index) => {
                      if (['m', 'at', 'abs'].includes(motif)) {
                        return data[name].absence[motif].map((motifItem, itemIndex) => (
                          <div key={itemIndex} className='elementsGridHeader3Line'>
                            <div className='elementsGridContent3LineCell'>
                              <p>{motif.toUpperCase()}</p>
                            </div>
                            <div>
                              <p>{formatDateToHumanReadable(motifItem.date)}</p>
                            </div>
                            <div>
                              <p>{motifItem.hours}</p>
                            </div>
                          </div>
                        ));
                      }
                      return null;
                    })}
                    <div className='elementsGridContent3LineTotal'>
                      <p>Total: {data[name].absence['tAbs']}h</p>
                    </div>

                  </div>
                    <div className='elementsGridContent4'>
                      {Object.keys(data[name].absence).map((motif, index) => {
                        if (['ca'].includes(motif)) {
                          return data[name].absence[motif].map((motifItem, itemIndex) => (
                            <div key={itemIndex} className='elementsGridHeader3Line'>
                              <div className='elementsGridContent3LineCell'>
                                <p>{motif.toUpperCase()}</p>
                              </div>
                              <div>
                                <p>{formatDateToHumanReadable(motifItem.date)}</p>
                              </div>
                              <div>
                                <p>{motifItem.hours}</p>
                              </div>
                            </div>
                          ));
                        }
                        return null;
                      })}
                      <div className='elementsGridContent3LineTotal'>
                        <p>Total: {data[name].absence['tCa']}h</p>
                      </div>
                  </div>
                  <div className='elementsGridContent5'>
                    <div className='elementsGridContent5Cell1'>
                      <p>{data[name].reason}</p>
                    </div>
                    <div className='elementsGridContent5Cell2'>
                      <p>{data[name].reasonAmount}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          </div>
      </div>
    </div>
);

};

export default ElementsVariablesPdf;
