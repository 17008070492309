import React from "react";
import LoadingModal from "./LoadingModal";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>Un problème est apparu...</h2>
          <p>Veuillez rafraîchir la page. Si le problème persiste, veuillez nous contacter:</p>
          <p><strong>Par mail:</strong> 📩 hello@augustinbrun.com</p>
          <p><strong>Par téléphone:</strong> 📞 +33 7 49 49 69 76</p>

        </div>);
    }


    return this.props.children;
  }
}

export default ErrorBoundary;
