import React from "react";

class ErrorThrowingComponent extends React.Component {
  componentDidMount() {
    throw new Error("Test error");
  }

  render() {
    return <div>Should not be displayed</div>;
  }
}

export default ErrorThrowingComponent;
