import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { convertToHoursAndMinutes } from '../utils/timeUtils';
import { useAuth0 } from '@auth0/auth0-react';


function TimesheetsViewAdmin({isScreenSmall, admin}) {
    const [timesheets, setTimesheets] = useState([]);
    const [users, setUsers] = useState([]);
    const { user, isLoading } = useAuth0();

    const [filter, setFilter] = useState({
        employeeName: '',
        fromDate: '',
        customerId: '',
        user: user
    });

    const convertDate = (dateToConvert) => {
      const date = new Date(dateToConvert);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear());
      return `${day}/${month}/${year}`;
    };

    function extractCustomerIds(dailyDetails) {
      return dailyDetails
          .map(detail => detail.customerId)
          .filter(id => id) // remove empty strings
          .join(', ');
    }


    const fetchTimesheets = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/timesheet/getTimesheetAdmin`, { params: {filter, user} });
        setTimesheets(response.data);
      } catch (error) {
        throw error;
      }

    }

    const handleFilterChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    }

    // Fetch users
    useEffect(() => {

      try {
        if (!isLoading) {
          async function fetchUsers() {
            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/users`, {params: {user: user}});
            setUsers(res.data);
          }
          fetchUsers();
        }
      } catch (error) {
        throw error
      }

    }, [isLoading, admin]);

    if (!admin) {
      return <h3>Chargement ...</h3>
    }

    return (

      <div>

        {isScreenSmall ? (
          <p>Cette page n'est disponible que version ordinateur</p>
        ) : (

          <div>
          <div className='timeSheetAdminHeader'>
            <div className='labelInput'>
              <label>Salarié</label>
              <select name="employeeName" value={filter.employeeName} onChange={handleFilterChange}>
                  <option value="">Sélectionner un salarié</option>
                  {users.map((user, i) => (
                      <option key={user.firstName + " " + user.lastName + i} value={user.firstName + " " + user.lastName}>{user.firstName + " " + user.lastName}</option>
                  ))}
              </select>
            </div>
            <div className='labelInput'>
              <label>Date</label>
              <input type="date" name='fromDate' value={filter.fromDate} onChange={handleFilterChange} />
            </div>
            <div className='labelInput'>
              <label>N° BL / BC</label>
              <input type="text" name="customerId" value={filter.customerId} onChange={handleFilterChange} />
            </div>
            <div>
              <button className="btn" onClick={fetchTimesheets}>Rechercher</button>
            </div>
          </div>

            {/* Display timesheets here */}
            <table className='timesheetViewAdminTable'>
                <thead>
                    <tr>
                        <th><h6>Date</h6></th>
                        <th><h6>Salarié</h6></th>
                        <th><h6>Total Hebdo</h6></th>
                        <th><h6>Total Hrs Supp</h6></th>
                        <th><h6>Total nuitées</h6></th>
                        <th><h6>Astreinte</h6></th>
                        <th><h6>N° BL / BC</h6></th>
                    </tr>
                </thead>
                <tbody>
                    {timesheets && timesheets.map((timesheet) => (
                        <tr key={timesheet._id}>
                            <td><p>{convertDate(timesheet.fromDate)}</p></td>
                            <td><p>{timesheet.employeeName}</p></td>
                            <td><p>{convertToHoursAndMinutes(timesheet.weeklyTotalHours)}</p></td>
                            <td><p>{convertToHoursAndMinutes(timesheet.overtimeHours)}</p></td>
                            <td><p>{timesheet.overnightStays}</p></td>
                            <td><p>{timesheet.onCallDuty ? 'Oui' : 'Non'}</p></td>
                            <td><p>{extractCustomerIds(timesheet.dailyDetails)}</p></td>
                            <td>
                              <button className='btn Link'><a href={`/feuille-heure-admin/${timesheet._id}`}>Voir</a></button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        )}
      </div>
    );
}

export default TimesheetsViewAdmin;
