import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

function DashboardCeoChart({ dataChart }) {
    // Transform the data
    const transformData = (data) => {

        return Object.keys(data).map(name => ({
            name,
            ...data[name]
        }));
    };

    const chartData = transformData(dataChart);

    // Extract unique service types from the data
    const allServiceTypes = [...new Set(chartData.flatMap(item => Object.keys(item)).filter(key => key !== 'name'))];

    return (
        <BarChart
            width={600}
            height={400}
            data={chartData}
            margin={{
                top: 20, right: 30, left: 20, bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name"/>
            <YAxis />
            <Tooltip />
            <Legend />
            {allServiceTypes.map((serviceType, index) => {
                const colors = ['#6528F7', '#F31559', '#068FFF', '#00DFA2', '#FC4F00', '#7149C6', '#0D1282', '#3E001F', '#A076F9', '#A2FF86'];  // Add more colors if you have more service types
                return <Bar key={serviceType} dataKey={serviceType} fill={colors[index % colors.length]} />;
            })}
        </BarChart>
    );
}

export default DashboardCeoChart;
