import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import '@fortawesome/fontawesome-svg-core/styles.css';

createRoot(document.getElementById("root")).render(
  <Auth0Provider
    domain="dev-tv0vsm0zw4f7at1l.us.auth0.com"
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    cacheLocation="localstorage"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://dev-tv0vsm0zw4f7at1l.us.auth0.com/api/v2/",
      scope: "read:current_user update:current_user_metadata email"
    }}
  >
    <Router>
      <App />
    </Router>
  </Auth0Provider>
);
