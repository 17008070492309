import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'




const InfosModal = ({ modalIsOpen, user, logout, isScreenSmall, admin}) => {
  if (!modalIsOpen) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modalContent">
        <div className='userInfos'>
          {user && <p>{user.name}</p>}
        </div>

        { isScreenSmall && (
        <div>
        <div>
          <p className="navigationTitleModal">NAVIGATION</p>
          <button className="navigationMenu">
            <a href='/'>📨 Déclarer feuille d'heure</a>
          </button>
          <button className="navigationMenu"><a href='/mes-feuilles-heures'>📑 Mes feuilles d'heure</a></button>
        </div>
        {admin === true && (

          <div>
          <p className="navigationTitleModal">ADMIN</p>
          <button className="navigationMenu">
            <a href='/liste-feuilles-heures'>🗂 Liste feuilles d'heures</a>
          </button>
          <button className="navigationMenu">
            <a href='/tableau-de-bord'>📊 Tableau de bord</a>
          </button>
        </div>
        )}
        <div>
          <p className="navigationTitleModal">AIDE</p>
          <button className="navigationMenu">
            <a href='/support'>⛑️ Support</a>
          </button>
        </div>
      </div>
        )}
        <button className="btn" onClick={() => {
          logout();
        }
      }>Se déconnecter</button>
      </div>
    </div>
  );
};

export default InfosModal;
