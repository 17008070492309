function Support() {

  return (
    <div className="supportView">
      <h3>Support</h3>
      <p>Si vous rencontrez un problème contactez-nous. Disponible 6j/7.</p>
      <div className="supportContact">
        <a href="mailto:hello@augustinbrun.com" className="btn">📩 hello@augustinbrun.com</a>
        <a href="tel:+33749496976" className="btn">📞 +33 7 49 49 69 76</a>
        <a href="https://wa.me/message/2GA36F2RP2SSK1" className="btn">💭 Whats App</a>
      </div>

    </div>
  )
}

export default Support;
