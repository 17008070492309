import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom'
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import axios from 'axios';


const OnboardingForm = ({user, setUsername}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.preventDefault();

    // Perform field validation
    let isValid = true;
    if (firstName.trim() === '') {
      setFirstNameError(true);
      isValid = false;
    } else {
      setFirstNameError(false);
    }

    if (lastName.trim() === '') {
      setLastNameError(true);
      isValid = false;
    } else {
      setLastNameError(false);
    }

    if (phone.trim() === '') {
      setPhoneError(true);
      isValid = false;
    } else {
      setPhoneError(false);
    }

    if (isValid) {
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/userOnboarding`, { auth0Id: user.sub, firstName, lastName, phone })
      .then(response => {
        const { firstName, lastName, phone, team } = response.data;
        // Redirect user to onboarding if any of the fields are not filled
        if (!firstName || !lastName || !phone || !team) {
          navigate('/onboarding');
        } else {
          setUsername(firstName + " " + lastName)
          // Redirect to dashboard or wherever you want
          navigate('/');
        }
      })
      .catch(error => {
        throw error
      });
    }
  };

  return (
    <div className="onboardingForm">
      <h6>Bonjour et bienvenue !</h6>
      <p>Quelques informations à fournir avant de commencer</p>
      <form onSubmit={handleSubmit}>
        <div className='labelInput'>
          <label>Prénom</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className={firstNameError ? 'text-input error' : 'text-input'}
            />
            {firstNameError && <p style={{color: "red", fontSize: "12px"}} className="error-message">Entrez votre prénom</p>}
        </div>
        <div className='labelInput'>
          <label>Nom</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className={lastNameError ? 'text-input error' : 'text-input'}
          />
          {lastNameError && <p style={{color: "red", fontSize: "12px"}} className="error-message">Entrez votre nom</p>}
        </div>
        <div className='labelInput'>
          <label>N° de téléphone</label>
          <PhoneInput
            international
            value={phone}
            defaultCountry={"FR"}
            onChange={setPhone}
            className={phoneError ? 'phone-input error' : 'phone-input'}
          />
          {phoneError && <p style={{color: "red", fontSize: "12px"}} className="error-message">Entrez votre numéro de téléphone</p>}
        </div>
        <button className="btn" type="submit">Suivant</button>

      </form>
    </div>
  );
};

export default OnboardingForm;
