export function capitalizeFirstAndThirdWord(str) {
  const words = str.split(' ');

  if (words.length >= 3) {
    words[0] = words[0][0].toUpperCase() + words[0].substring(1);
    words[2] = words[2][0].toUpperCase() + words[2].substring(1);
  }

  return words.join(' ');
}
