import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const TimesheetHeatmap = ({isScreenSmall, admin}) => {
    const [data, setData] = useState([]);
    const months = ["Janv", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Août", "Sep", "Oct", "Nov", "Déc"];
    const weeksInMonths = [4, 4, 5, 4, 4, 4, 5, 4, 4, 5, 4, 4];
    const { user, isLoading } = useAuth0();
    const [year, setYear] = useState("2024")


    const fetchData = async (year) => {
      try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/timesheet/timesheetsValidation`, {params: {user: user, year: year}});
          const transformedData = transformData(response.data);
          console.log(transformedData)
          setData(transformedData);
          setYear(year)
      } catch (error) {
          console.error("Error fetching data:", error);
      }
  };

    useEffect(() => {

      if (!isLoading) {
        const fetchData = async () => {
          try {
              const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/timesheet/timesheetsValidation`, {params: {user: user, year: year}});
              const transformedData = transformData(response.data);
              console.log(transformedData)
              setData(transformedData);
          } catch (error) {
              console.error("Error fetching data:", error);
          }
      };

      fetchData();
      }
    }, [isLoading, admin]);

    const getWeekColumn = (weekIdx) => {
      let column = 2;  // start after the name column
      for (let i = 0; i < weeksInMonths.length; i++) {
          if (weekIdx < weeksInMonths[i]) return column + weekIdx;
          weekIdx -= weeksInMonths[i];
          column += weeksInMonths[i];
      }
    }

  const transformData = (rawData) => {
    const result = [];

    const formatFrenchDate = (dateStr) => {
        const dateObj = new Date(dateStr);

        const days = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
        const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

        const dayName = days[dateObj.getDay()];
        const monthName = months[dateObj.getMonth()];

        return `${dayName} ${dateObj.getDate()} ${monthName} ${dateObj.getFullYear()}`;
    };
    console.log(rawData)
    for (let name in rawData) {
        const weeksData = rawData[name];
        const weeks = new Array(52).fill(null);
        for (let week in weeksData) {
            const weekData = weeksData[week];

            if (weekData.fromDate) {
                weekData.fromDate = formatFrenchDate(weekData.fromDate);
            }

            weeks[parseInt(week) - 1] = weekData;
        }
        result.push({ name, weeks });
    }

    return result;
};

  if (!admin) {
    return <div><h3>Chargement ...</h3></div>;
  }
  if (data.length === 0) {
    return (
    <div>
      <div className='selectYearBtn'>
        <button className="btn" onClick={() => fetchData("2023")}>2023</button>
        <button className="btn" onClick={() => fetchData("2024")}>2024</button>
        <p>Aucune donnée pour {year} ...</p>
      </div>

    </div>)
  }

return (

  <div>
    {isScreenSmall ? (
      <p>Cette page n'est disponible que version ordinateur</p>
    ) : (
    <div>
      <div className='selectYearBtn'>
        <button className="btn" onClick={() => fetchData("2023")}>2023</button>
        <button className="btn" onClick={() => fetchData("2024")}>2024</button>
      </div>
      <div className="heatmap">

      <div className="header"><p>Salarié</p></div>
      {months.map((month, idx) => (
          <div key={month} className="header month" style={{ gridColumn: `span ${weeksInMonths[idx]}` }}>
              <p>{month}</p>
          </div>
      ))}
      {data.map((employee, empIdx) => [
        <div key={employee.name} className="name" style={{ gridRow: empIdx + 2 }}>
            <p>{employee.name}</p>
        </div>,
        ...employee.weeks.map((weekData, weekIdx) => (
          <div
          key={weekIdx}
          className={`week ${weekData ? weekData.status : 'notSubmitted'}`}
          style={{ gridRow: empIdx + 2, gridColumn: getWeekColumn(weekIdx) }}
          >
          {weekData && weekData._id && (
              <Link to={`/feuille-heure-admin/${weekData._id}`}></Link>
          )}
          {weekData && weekData.fromDate && (
              <div className="fromDateTooltip">
                  <p>{weekData.fromDate}</p>
              </div>
          )}
          </div>


        ))
    ])}
    </div>
    </div>
    )}
  </div>
);


}

export default TimesheetHeatmap;
