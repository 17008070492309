import logo from '../img/gestion-temps-travail-logo.png'


const Navigation = ({admin}) => {
  return (
    <div className="navigationContainer">
      <div>
        <div className='companyInfos'>
          <img src={logo} />
        </div>
        <div>
          <p className="navigationTitle">DOCUMENTS</p>
          <button className="navigationMenu">
            <a href='/'>📨 Déclarer feuille d'heure</a>
          </button>
          <button className="navigationMenu"><a href='/mes-feuilles-heures'>📑 Mes feuilles d'heure</a></button>
        </div>
        {admin === true && (
          <div>
          <p className="navigationTitle">ADMIN</p>
          <button className="navigationMenu">
            <a href='/liste-feuilles-heures'>🗂 Liste feuilles d'heures</a>
          </button>
          <button className="navigationMenu">
            <a href='/tableau-de-bord'>📊 Tableau de bord</a>
          </button>
          <button className="navigationMenu">
          <a href='/feuilles-validees'>✅ Feuilles validées</a>
          </button>
          <button className="navigationMenu">
          <a href='/elements-variables'>📒 Éléments variables</a>
          </button>
          <button className="navigationMenu">
          <a href='/parametres'>⚙️ Paramètres</a>
          </button>
        </div>
        )}
        <div>
          <p className="navigationTitle">AIDE</p>
          <button className="navigationMenu">
            <a href='/support'>⛑️ Support</a>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Navigation;
